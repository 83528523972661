import styled, { DefaultTheme, css } from "styled-components";

import dynamic from "next/dynamic";

const LoadingIndicator = dynamic(
  () => import("@/client/components/LoadingIndicator")
);

export type Variants = "default" | "positive" | "negative" | "ghost" | "link";
export type Icons = "left" | "right" | "leftAndRight" | "iconOnly" | null;

function getTextAndIndicatorColor(
  variant: Variants,
  colors: DefaultTheme["colors"]
) {
  const data: Record<Variants, string> = {
    default: colors.text.primary,
    ghost: colors.text.secondary,
    link: colors.text.linkInverted,
    negative: colors.text.primaryInverted,
    positive: colors.text.primaryInverted,
  };

  return data[variant];
}
const StyledButton = styled.button<{
  icon?: Icons;
  overrideColor?: React.CSSProperties["color"];
  accentColor?: React.CSSProperties["color"] | null;
  size?: "s" | null;
  variant?: Variants;
}>`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 20px;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: ${(props) => (props.size == "s" ? "0.5rem" : "1rem")};

  cursor: pointer;
  background: ${(props) => props.theme.colors.fill.base};
  color: ${({ theme, variant }) =>
    getTextAndIndicatorColor(variant ?? "default", theme.colors)};
  border: 1px solid ${(props) => props.theme.colors.divider.default};
  box-shadow: ${(props) => props.theme.shadows.button};
  white-space: nowrap;
  svg {
    fill: ${(props) => props.theme.colors.icon.primary};
    font-size: 16px;
  }
  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors.fill.baseSecondary};
  }
  &:active {
    background: ${(props) => props.theme.colors.fill.light};
    box-shadow: ${(props) => props.theme.shadows.button_active};
  }
  &:disabled {
    background: ${(props) => props.theme.colors.fill.base};
    color: ${(props) => props.theme.colors.text.muted};
    border-color: ${(props) => props.theme.colors.fill.mediumLight};
    cursor: not-allowed;
  }

  //positive button styles
  ${(props) =>
    props.variant == "positive" &&
    css`
      color: ${getTextAndIndicatorColor(props.variant, props.theme.colors)};
      background-color: ${(props) => props.theme.colors.positive.default};
      border-color: ${(props) => props.theme.colors.positive.default};

      svg {
        fill: ${(props) => props.theme.colors.text.primaryInverted};
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.positive.hover};
        border-color: ${(props) => props.theme.colors.positive.hover};
      }

      &:active,
      &:focus {
        background-color: ${(props) => props.theme.colors.positive.strong};
      }

      &:disabled,
      &:disabled:hover,
      &:disabled:hover:active {
        background-color: ${(props) => props.theme.colors.positive.muted};
        color: ${(props) => props.theme.colors.text.primaryInverted};
        border-color: ${(props) => props.theme.colors.positive.muted};
        cursor: not-allowed;

        div > svg,
        svg {
          fill: ${(props) => props.theme.colors.text.primaryInverted};
        }
      }
    `}

  //negative button styles
  ${(props) =>
    props.variant == "negative" &&
    css`
      color: ${getTextAndIndicatorColor(props.variant, props.theme.colors)};

      background-color: ${(props) => props.theme.colors.negative.default};
      border-color: ${(props) => props.theme.colors.negative.default};

      svg {
        fill: ${(props) => props.theme.colors.text.primaryInverted};
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.negative.hover};
        border-color: ${(props) => props.theme.colors.negative.hover};
      }

      &:active,
      &:focus {
        background-color: ${(props) => props.theme.colors.negative.strong};
      }

      &:disabled {
        background-color: ${(props) => props.theme.colors.negative.muted};
      }
    `}



    //ghost button styles
  ${(props) =>
    props.variant == "ghost" &&
    css`
      color: ${getTextAndIndicatorColor(props.variant, props.theme.colors)};
      background-color: ${(props) => props.theme.colors.fill.transparent};
      border: none;
      box-shadow: none;

      svg {
        fill: ${getTextAndIndicatorColor(props.variant, props.theme.colors)};
      }

      &:hover {
        color: ${(props) => props.theme.colors.text.primary};
        background-color: ${(props) =>
          props.theme.colors.fill.baseSecondaryTransparent};
        box-shadow: none;
        svg {
          fill: ${(props) => props.theme.colors.text.primary};
        }
      }

      &:active,
      &:focus {
        background-color: ${(props) =>
          props.theme.colors.fill.lightTransparent};
        box-shadow: none;
        svg {
          fill: ${(props) => props.theme.colors.text.primary};
        }
      }

      &:disabled {
        color: ${(props) => props.theme.colors.text.mutedTransparent};
        background-color: transparent;
        cursor: not-allowed;
        box-shadow: none;
        svg {
          fill: ${(props) => props.theme.colors.text.mutedTransparent};
        }
      }
    `}

       

     //link button styles
  ${(props) =>
    props.variant == "link" &&
    css`
      font-size: 12px;
      color: ${getTextAndIndicatorColor(props.variant, props.theme.colors)};
      background-color: ${(props) => props.theme.colors.fill.transparent};
      border: none;
      box-shadow: none;

      svg {
        fill: ${(props) => props.theme.colors.text.linkInverted};
      }

      &:hover {
        color: ${(props) => props.theme.colors.negative.hover};
        background-color: ${(props) =>
          props.theme.colors.fill.lightTransparent};
        box-shadow: none;
        text-decoration: underline;
        svg {
          fill: ${(props) => props.theme.colors.negative.hover};
        }
      }

      &:active,
      &:focus {
        color: ${(props) => props.theme.colors.active.default};

        background-color: ${(props) =>
          props.theme.colors.fill.lightTransparent};
        box-shadow: none;
        svg {
          fill: ${(props) => props.theme.colors.active.default};
        }
      }

      &:disabled {
        color: ${(props) => props.theme.colors.text.linkMuted};
        box-shadow: none;
        svg {
          fill: ${(props) => props.theme.colors.text.mutedTransparent};
        }
      }
    `}


  //start icon button styles
  ${(props) =>
    props.icon == "left" &&
    css`
      padding-left: 0.5rem;
      svg {
        margin-right: 0.25rem;
      }
    `}

  ${(props) =>
    props.icon == "right" &&
    css`
      padding-right: 0.5rem;
      svg {
        margin-left: 0.25rem;
      }
    `}

      ${(props) =>
    props.icon == "leftAndRight" &&
    css`
      padding-left: 0.25rem;
      padding-right: 0.25rem;

      svg {
        margin: 0 0.25rem;
      }
    `}

  ${(props) =>
    props.icon == "iconOnly" &&
    css`
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    `}
  ${(props) =>
    props.overrideColor != null &&
    css`
      background-color: ${props.overrideColor};
    `}
    ${(props) =>
    props.accentColor != null &&
    css`
      border-color: ${props.accentColor};
      border-style: solid inset solid solid;
      border-left-width: 10px;
    `}
`;

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  accentColor?: React.CSSProperties["color"] | null;
  children: JSX.Element | JSX.Element[] | string;
  icon?: Icons;
  loading?: boolean;
  loadingIndicatorColor?: string;
  overrideColor?: React.CSSProperties["color"];
  size?: "s" | null;
  style?: React.CSSProperties;
  type?: "submit" | "button";
  variant?: Variants;
};

export default function Button(props: ButtonProps): JSX.Element {
  const {
    accentColor,
    children,
    loading,
    loadingIndicatorColor,
    overrideColor,
    type,
    variant = "default",
    ...rest
  } = props;

  return (
    <StyledButton
      {...rest}
      type={type ?? "button"}
      variant={variant}
      overrideColor={overrideColor}
      accentColor={accentColor}
    >
      {typeof loading === "boolean" && loading ? (
        <StyledLoadingRoot>
          <StyledLoadingWrapper>
            <LoadingIndicator
              size={20}
              centered={false}
              color={loadingIndicatorColor}
            />
          </StyledLoadingWrapper>
          <span style={{ visibility: "hidden" }}> {children} </span>
        </StyledLoadingRoot>
      ) : (
        <>{children}</>
      )}
    </StyledButton>
  );
}

const StyledLoadingRoot = styled.div`
  position: relative;
`;

const StyledLoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
