var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"_cT3Kk94jHJJNOeydop-n"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const NOT_USE_SENTRY_LOCAL = process.env.NOT_USE_SENTRY_LOCAL;

if (NOT_USE_SENTRY_LOCAL !== "true") {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://0da4b71cf4b3471cba6845b07598e3e4@o1118444.ingest.sentry.io/4504677857624064",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.3,
    environment: process.env.SENTRY_ENV,
    beforeSend(event) {
      if (
        event &&
        event.exception &&
        Array.isArray(event.exception.values) &&
        event.exception.values[0].type === "SwrError"
      ) {
        return null;
      }
      return event;
    },
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
